import axios from 'axios'

let debounce = (func, wait, immediate) => {
    var timeout;
    return () => {
        let context = this, args = arguments;
        let later = function() {
            timeout = null;
            if (!immediate) func.apply(context, args);
        };
        let callNow = immediate && !timeout;
        clearTimeout(timeout);
        timeout = setTimeout(later, wait);
        if (callNow) func.apply(context, args);
    };
};

document.addEventListener("turbolinks:load", () => {
    if (document.getElementById('bookSearch') !== null) {
        let csrfToken = document.querySelector("meta[name=csrf-token]").content;

        let libraryDropdown = document.getElementById('libraryDropdown');
        let searchBar = document.getElementById('bookSearchBar');
        let bookList = document.getElementById('bookList');
        let searchCategoryDropdown = document.getElementById('searchCategoryDropdown');
        let showMoreButton = document.getElementById('showMoreButton');
        let totalBookCountIndicator = document.getElementById('totalBookCountIndicator');

        let toggleShowMoreButton = () => {
            let totalCount = searchBar.getAttribute('total-book-count');
            let displayedCount = bookList.childElementCount;

            showMoreButton.style.visibility = displayedCount < totalCount ? 'visible' : 'hidden';
        };

        searchBar.addEventListener('keyup', debounce(() => {
            let libraryName = libraryDropdown.getAttribute('library-name');
            let category = searchCategoryDropdown.getAttribute('search-category');
            let keyword = searchBar.value;
            let page = 1;

            axios.get('/book-search', {
                params: { library_name: libraryName, category: category, keyword: keyword, page: page },
                headers: { 'X-CSRF-Token': csrfToken },
                withCredentials: true
            }).then(response => {
                bookList.innerHTML = response.data;
                searchBar.setAttribute('page', page);

                axios.get('/book-search-count', {
                    params: { library_name: libraryName, category: category, keyword: keyword },
                    headers: { 'Content-Type': 'application/json', 'X-CSRF-Token': csrfToken },
                    withCredentials: true
                }).then(response => {
                    searchBar.setAttribute('total-book-count', response.data.count);
                    totalBookCountIndicator.innerText = response.data.count;

                    toggleShowMoreButton();
                }).catch(_error => {});
            }).catch(_error => {});

        }, 200));

        showMoreButton.addEventListener('click', () => {
            let libraryName = libraryDropdown.getAttribute('library-name');
            let category = searchCategoryDropdown.getAttribute('search-category');
            let keyword = searchBar.value;
            let page = parseInt(searchBar.getAttribute('page')) + 1;

            axios.get('/book-search', {
                params: { library_name: libraryName, category: category, keyword: keyword, page: page },
                headers: { 'X-CSRF-Token': csrfToken },
                withCredentials: true
            }).then(response => {
                bookList.innerHTML += response.data;
                searchBar.setAttribute('page', page);
                toggleShowMoreButton();
            }).catch(_error => {});
        });
    }
});
