let toggleDuplicateAuthorWarning = () => toggleDuplicateInputWarning('author-input');
let toggleDuplicateTranslatorWarning = () => toggleDuplicateInputWarning('translator-input');

let toggleDuplicateInputWarning = (className) => {
  let inputs = Array.from(document.getElementsByClassName(className));

  inputs.forEach(input => {
    input.classList.remove('is-invalid');
  });

  for (let i = 0; i < inputs.length; i++) {
    for (let j = 0; j < inputs.length; j++) {
      value1 = normalizeInputValue(inputs[i].value);
      value2 = normalizeInputValue(inputs[j].value);

      if (i !== j && value1 !== '' && value2 !== '' && value1 === value2) {
        inputs[i].classList.add('is-invalid');
        inputs[j].classList.add('is-invalid');
      }
    }
  }
};

let normalizeInputValue = (value) => {
  return value.toLocaleLowerCase('tr-TR').trim();
};

document.addEventListener("turbolinks:load", () => {
  if (document.getElementsByClassName('book-form').length >= 1) {
    let authorGroup = document.getElementById('authorGroup');
    let addAuthorButton = document.getElementById('addAuthorButton');

    let translatorGroup = document.getElementById('translatorGroup');
    let addTranslatorButton = document.getElementById('addTranslatorButton');

    addAuthorButton.addEventListener('click', () => {
      let authorInputToCopy = document.querySelector('#authorGroup div.form-group:last-child').cloneNode(true);
      authorInputToCopy.querySelector('input').value = '';
      authorGroup.appendChild(authorInputToCopy);

      toggleDuplicateAuthorWarning();
    });

    addTranslatorButton.addEventListener('click', () => {
      let translatorInputToCopy = document.querySelector('#translatorGroup div.form-group:last-child').cloneNode(true);
      translatorInputToCopy.querySelector('input').value = '';
      translatorGroup.appendChild(translatorInputToCopy);

      toggleDuplicateTranslatorWarning();
    });

    document.addEventListener('keyup', (event) => {
      if (event.target && event.target.classList.contains('author-input')) {
        toggleDuplicateAuthorWarning();
      }

      if (event.target && event.target.classList.contains('translator-input')) {
        toggleDuplicateTranslatorWarning();
      }
    });
  }
});
